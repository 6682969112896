import { Disclosure } from '@headlessui/react'
import { Transition } from '@headlessui/react'
import { Bars3Icon, XMarkIcon,  } from '@heroicons/react/24/outline'
import VomoLogo from '../misc/VocamotionLogo.png'
import { ChevronDownIcon } from '@heroicons/react/20/solid'

const navigation = [
  { name: 'About', href: '/about', current: false },
  { name: 'Audition Info', href: '/auditions', current: false },
  { name: 'Events', href: '', current: false },
  { name: 'Donations', href: '/donations', current: false },
  { name: 'Member Portal', href: 'https://bhsboston.choirgenius.com/user/login', current: false },
]

const eventItems = [
  { name: 'Chapter Show', href: '/chapter-show', text: 'Chapter Show - 6/7 & 6/8' },
  { name: 'BHS International (+ Fundraiser)', href: '/international', text: 'BHS International - 7/4' },
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavBar() {
  return (
    <Disclosure as="nav" className="bg-slate-950 z-40 object-cover object-center fixed inset-0 h-16 w-full">
      {({ open }) => (
        <>
          <div className="mx-auto sm:w-11/12 px-1">
            <div className="relative flex h-16 items-center justify-between">
              <div className="absolute inset-y-0 right-0 flex items-center sm:hidden">
                {/* Mobile menu button*/}
                <Disclosure.Button className="relative inline-flex items-center justify-center rounded-md p-2 text-gray-400  hover:text-blue-400 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white">
                  <span className="absolute -inset-0.5" />
                  {open ? (
                    <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
                  ) : (
                    <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
                  )}
                </Disclosure.Button>
              </div>
              <div className="flex flex-1 items-baseline justify-center sm:items-center sm:justify-start h-full">
                <div className="flex flex-shrink-0 items-center">
                  <a className="no-underline" href="/">
                    <img
                      className="h-14 w-auto vomoLogo"
                      src={VomoLogo}
                      alt="VocaMotion Logo"
                    />
                  </a>
                </div>
                
                <div className="hidden sm:ml-6 sm:block h-full">
                  <div className="flex space-x-4 h-full">
                    {navigation.map((item) => (
                      <a
                        key={item.name}
                        href={item.href}
                        className={classNames(
                          `${item.name}`,
                          item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:text-blue-400',
                          'rounded-md px-3 py-2 text-sm font-medium no-underline h-full leading-[46px]'
                        )}
                        aria-current={item.current ? 'page' : undefined}
                      >
                        {item.name}
                        {item.name === 'Events' && 
                          <ul className="navDropdown rounded-b-lg">
                            {eventItems.map((dd) => (
                              <a className="text-gray-300 hover:text-blue-400" href={dd.href}>
                                <li className="dditem border-b border-b-solid border-b-slate-700 text-sm font-medium text-gray-300 hover:text-blue-400">{dd.text}</li>
                              </a>
                            ))}
                          </ul>
                        }
                      </a>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <Transition
          enter="transition ease duration-500 transform"
          enterFrom="opacity-0 -translate-y-4"
          enterTo="opacity-100 translate-y-0"
          leave="transition ease duration-300 transform"
          leaveFrom="opacity-100 translate-y-0"
          leaveTo="opacity-0 -translate-y-4"
          >
          <Disclosure.Panel className="sm:hidden flex justify-center bg-slate-950">
            <div className="space-y-1 px-2 pb-3 pt-2 w-full">
              {navigation.map((item) => (
                item.name === "Events" ? (
                  <Disclosure>
                    {({ open }) => (
                      <>
                      <Disclosure.Button key={item.name} as="a" className={classNames(
                        item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:text-blue-400',
                          'block rounded-md px-3 py-1 text-base font-medium justify-center text-center no-underline w-full flex flex-row items-center gap-2'
                        )}>Events
                        <ChevronDownIcon
                          className={`${
                            open ? 'rotate-180 transform' : ''
                          } h-5 w-5`}
                        />
                      </Disclosure.Button>
                      <Transition
                      enter="transition ease duration-500 transform"
                      enterFrom="opacity-0 -translate-y-1"
                      enterTo="opacity-100 translate-y-0"
                      leave="transition ease duration-300 transform"
                      leaveFrom="opacity-100 translate-y-0"
                      leaveTo="opacity-0 -translate-y-1"
                      >
                      <Disclosure.Panel>
                        <ul className="eventsDropdown bg-slate-900">
                          {eventItems.map((dd) => (
                            <a className="text-gray-300 hover:text-blue-400" href={dd.href}>
                              <li className="dditem text-sm font-medium">{dd.text}</li>
                            </a>
                          ))}
                        </ul>
                      </Disclosure.Panel> 
                      </Transition>
                    </> )}
                  </Disclosure>
                ) : (
                  <Disclosure.Button
                    key={item.name}
                    as="a"
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:text-blue-400',
                      'block rounded-md px-3 py-1 text-base font-medium justify-center text-center w-full'
                    )}
                    aria-current={item.current ? 'page' : undefined}
                  >
                    {item.name}
                  </Disclosure.Button>
                )
              ))}
            </div>
          </Disclosure.Panel>
          </Transition>
        </>
      )}
    </Disclosure>
  )
}