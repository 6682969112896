// import eventBanner1 from '../../misc/nightandday.jpg';

export default function GenericShowPageTBD () {
    return (
        <>
        <h1 class="h1 text-center w-11/12 m-auto pt-8 pb-12" id="subPageHeaderTitle">Chapter Show - 6/7 & 6/8</h1>
            <div className='w-11/12 m-auto mt-0'>
                <div className='text-justify pb-10'>
                    <div className='bg-slate-800 rounded-2xl p-5'>
                            <p>
                                STAY TUNED! MORE INFO TO COME!!!
                            </p>
                    </div> 
                </div>
            </div>
        </>
    )
}