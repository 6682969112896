import banner from "../../misc/vomoChristmasBanner.png";

export default function International () {
    return (
        <>
        <h1 class="h1 text-center w-11/12 m-auto pt-8 pb-12" id="subPageHeaderTitle">VocaMotion at BHS International 2025</h1>
            <div className='w-11/12 m-auto mt-0'>
                <div className='text-justify pb-10'>
                    <div className='bg-slate-800 rounded-2xl p-5'>
                        <img className='m-auto h-1/2 w-1/2' src={banner} alt='International Banner'></img>
                        <br></br>
                        <p>VocaMotion is thrilled to be the Northeastern District representative at this year's BHS international in Denver! Just 2.5 years after our founding, we are so grateful to have this opportunity to further improve our skills and spread a little bit of Greater Boston joy in Denver.</p>
                        <p>
                        Of course, travelling and competing incurs a significant cost for the chorus, so we are working to fundraise what we can to help defray those costs. 100% of your tax-deductible donation will go towards supporting our members and organization in making this next step in our journey! Thank you all for your continued support!
                        </p>
                         <p>To donate to our international fundraiser, click the button below:</p>
                        <div className ='flex justify-center pt-3'>
                            <a className='w-[39%]' href="https://www.zeffy.com/en-US/fundraising/vocamotion-international-competition-fund"><button style={{width:'100%'}} className ="homePageButton w-full" id="auditionInfoButton">
                            Donate Now!
                            </button>
                            </a>
                        </div>
                    </div> 
                </div>
            </div>
        </>
    )
}