import cayHeadshot from '../misc/Cayheadshot2023.jpg';
// import leahHeadshot from '../misc/Leahheadshot2023.jpg';
// import aleenaHeadshot from '../misc/Aleenaheadshot2023.jpg';
import ciaraHeadshot from '../misc/Ciaraheadshot.jpg';
import jackieHeadshot from '../misc/Jackieheadshot.jpg';
import katrinaHeadshot from '../misc/Katrinaheadshot2023.jpg';
import michelleHeadshot from '../misc/Michelleheadshot2023.png';
import haleyHeadshot from '../misc/Haleyheadshot2023.png';

export default function LeadershipTeamBubbles() {
  const teamMembers = [
    { name: "Cay Outerbridge", chorusTitle: "Frontline Director", headshot: cayHeadshot, email: "" },
    { name: "Katrina Mroz", chorusTitle: "Chorus President", headshot: katrinaHeadshot, email: "president@vocamotion.org" },
    { name: "Ciara O'Donnell", chorusTitle: "Chorus Manager", headshot: ciaraHeadshot, email: "manager@vocamotion.org" },
    { name: "Jackie Orent-Nathan", chorusTitle: "Chorus Membership", headshot: jackieHeadshot, email: "membership@vocamotion.org" },
    { name: "Michelle Cunningham", chorusTitle: "Music Team Lead", headshot: michelleHeadshot, email: "music@vocamotion.org" },
    { name: "Haley Cole", chorusTitle: "Chorus Marketing", headshot: haleyHeadshot, email: "marketing@vocamotion.org" }
  ];

  return (
    <>
      <div className="gridContainer grid w-full justify-between m-auto pb-12 break-word items-baseline pt-10">
        {teamMembers.map((member, index) => (
          <div key={index} className='gridItem photoCard flex flex-col object-contain w-auto h-auto items-center'>
                <div className='items-center justify-center'>
                    <img src={member.headshot} className='rounded-full m-auto max-w-[50%]' alt={`${member.name} Headshot`} />
                </div>
                <div className='imageDescription flex flex-col align-center text-center'>
                    <div className='pt-3 uppercase tracking-wider'>{member.name}</div>
                    <div className='pt-1 italic tracking-wider chorusTitle'>{member.chorusTitle}</div>
                    <div className='pt-1 tracking-wider contactEmail'>{member.email}</div>
                </div>
            </div>
            ))}
        </div>
    </>
  )
}
